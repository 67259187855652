import React, { createContext, useReducer, useContext, ReactNode, Dispatch } from 'react';

// Define the shape of the loading state
interface LoadingState {
  isLoading: boolean;
}

// Define the types of actions
type LoadingAction = { type: 'START_LOADING' } | { type: 'STOP_LOADING' };

// Initial state
const initialState: LoadingState = { isLoading: true };

// Reducer function
const loadingReducer = (state: LoadingState, action: LoadingAction): LoadingState => {
  switch (action.type) {
    case 'START_LOADING':
      return { isLoading: true };
    case 'STOP_LOADING':
      return { isLoading: false };
    default:
      return state;
  }
};

// Define context type
interface LoadingContextType {
  state: LoadingState;
  dispatch: Dispatch<LoadingAction>;
}

// Create the context with type
const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

// Provider component
export const LoadingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(loadingReducer, initialState);
  return (
    <LoadingContext.Provider value={{ state, dispatch }}>
      {children}
    </LoadingContext.Provider>
  );
};

// Custom hook to use the loading context
export const useLoading = (): LoadingContextType => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return context;
};