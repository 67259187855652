import React, { useEffect, Suspense, lazy } from 'react'
import AOS from 'aos';
import { Analytics } from "@vercel/analytics/react"
import 'aos/dist/aos.css';
import { LoadingProvider } from './context/loadingContext';

const MainPage = lazy(() => import('./pages/Main'));

const App: React.FC = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <Suspense>
      <LoadingProvider>
        <MainPage />
        <Analytics />
      </LoadingProvider>
    </Suspense>
  )

}

export default App;
