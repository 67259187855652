import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import WebFont from 'webfontloader';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
WebFont.load({
  google: {
    families: ['Montserrat:ital,wght@0,100..900;1,100..900']
  },
  active() {
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  },
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
